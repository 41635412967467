import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ApmErrorHandler, ApmService } from '@elastic/apm-rum-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SortablejsModule } from 'ngx-sortablejs';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './/app-routing.module';
import { ApolloConfigModule } from './apollo-config/apollo-config.module';
import { AppComponent } from './app.component';
import { getMetaReducers, reducers } from './app.reducer';
import { SessionModule } from './session/session.module';
import { TokenInterceptor } from './session/token.interceptor';
import { MatPaginatorIntlDe } from './shared/mat-paginator-intl-de.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    StoreModule.forRoot(reducers, { runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    SessionModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    ApolloConfigModule, // Add this
    SortablejsModule.forRoot({ animation: 150 })
    //ApmModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    {
      provide: MatPaginatorIntl, 
      useClass: MatPaginatorIntlDe
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    {
      provide: ApmService,
      useClass: ApmService,
      deps: [Router]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(@Inject(ApmService) service: ApmService) {
    const apm = service.init({
      active: environment.apmActive,
      serviceName: environment.name,
      serverUrl: environment.apmServerUrl,
      distributedTracingOrigins: [environment.generalBackendUri],
      environment: environment.env,
      breakdownMetrics: true,
      serviceVersion: environment.version,
      disableInstrumentations: ['xmlhttprequest']
    });

  }

}
